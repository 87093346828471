@import url('https://fonts.googleapis.com/css?family=Roboto+Mono|Roboto:300,400,500');

body {
	height: auto !important;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
		sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
		'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
	font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-weight: 400;
	margin: 0;
}

nav {
	position: fixed;
	top: 0;
	width: 150px;
	min-height: 100%;
	z-index: 1100;
	background-color: white;
}

.ui.header:first-child {
	margin-top: 0;
}

header .ui.header h2 {
	font-weight: 300;
}

.container {
	min-height: 100vh;
}

.openSidebar .container {
	-webkit-transition: -webkit-transform 0.5s ease;
	transition: -webkit-transform 0.5s ease;
	-o-transition: transform 0.5s ease;
	transition: transform 0.5s ease;
	transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.closedSidebar .container {
	-webkit-transition: -webkit-transform 0.5s ease;
	transition: -webkit-transform 0.5s ease;
	-o-transition: transform 0.5s ease;
	transition: transform 0.5s ease;
	transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.openSidebar nav {
	-webkit-transition: -webkit-transform 0.5s ease;
	transition: -webkit-transform 0.5s ease;
	-o-transition: transform 0.5s ease;
	transition: transform 0.5s ease;
	transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.closedSidebar nav {
	transform: translate3d(-100%, 0px, 0px);
	-webkit-transition: -webkit-transform 0.5s ease;
	transition: -webkit-transform 0.5s ease;
	-o-transition: transform 0.5s ease;
	transition: transform 0.5s ease;
	transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.container-full-page {
	display: block;
	font-size: 1.14285714rem;
	line-height: 1.5;
}

@media (min-width: 768px) and (max-width: 1193px) {
	html,

	nav {
		box-shadow: none;
	}

	.container-full-page {
		margin-left: 3px;
	}
}

@media (min-width: 1194px) {
	.container-full-page {
		max-width: 1200px;
		margin: 1em auto;
		border: solid 1px rgba(0, 0, 0, 0.15);
		border-radius: 0.28571429rem;
		box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
			0 2px 10px 0 rgba(34, 36, 38, 0.15);
	}
}

.container-full-page .container-header {
	padding: 1em;
	border-bottom: solid 1px #d4d4d5;
	border-radius: 5px 5px 0 0;
	background: #f9fafb;
}

.container-full-page .header-sticky {
	position: sticky;
	top: 62px;
	z-index: 1;
}

.container-full-page .container-header > .ui.basic.buttons {
	background: #ffffff;
}

.container-full-page .ui.segment {
	padding: 0;
}

@media (max-width: 767px) {
	.container-full-page .container-header {
		padding: 0;
		background: #ffffff;
		border-radius: 0;
		border-top: solid 1px #d4d4d5;
	}

	.container-full-page .header-sticky {
		top: 50px;
	}

	.container-full-page .container-header .detail-toggle {
		margin-top: 0;
	}
}

.container-full-page .container-item {
	padding: 1em;
	background: #f9fafb;
	word-break: break-all;
}

.mono {
	font-family: 'Roboto Mono', monospace;
	color: #686869;
}

.ui.modal.transaction > .content {
	padding: 0 1px !important;
}
