.investment-transaction-form .ui.input{
	margin-top: .2em;
}

.investment-transaction-form .ui.fluid.dropdown {
	margin-top: .2em;
}

.investment-transaction-form .ui.button {
	margin-top: .2em;
}
